import React, { Component } from 'react';
import '../../../src/styles/components/headerUser.scss';
import { connect } from 'react-redux';
import { TYPES } from '../../actions';
import { getUserInfo, guessSalonGet, cleanUserInfo } from '../../actions';
import _ from 'lodash';
class HeaderUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salonName: '',
      line_id: new URLSearchParams(window.location.search).get('line_user_id') ?? '',
      salon_uuid: new URLSearchParams(window.location.search).get('sid') ?? '',
    };
  }
  componentDidMount() {
    if (this.state.salon_uuid) {
      if (this.state.line_id) {
        this.props.getUserInfo({
          line_id: this.state.line_id,
          salon_uuid: this.state.salon_uuid,
        });
      }
      this.props.guessSalonGet({ code: this.state.salon_uuid });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('HeaderUser UNSAFE_componentWillReceiveProps props: ', props);
    const { type } = props.actionNotify;
    if (!type) return;
    switch (type) {
      case TYPES.ACTION_GET_GUESS_SALON_SUCCESS:
        if (_.get(props, 'actionNotify.salon_status', '') === "close"){
          let role = _.get(this.props, 'userInfo.role');
          let isStaff = role === 1;
          if (isStaff) {
            this.props.cleanUserInfo();
            localStorage.removeItem('salon_token');
            window.location.href = '/login';
          }else{
            let route = `/booking/salon-suspended`;
            if (this.props.history) {
              this.props.history.push(route);
            } else {
              window.location.href = route;
            }
          }
          return
        }

        this.setState({
          salonName: _.get(props, 'actionNotify.salon_name', ''),
        });
        break;
    }
  }
  render() {
    return (
      <div className="w-100 text-dark mb-30 title-header_user">
        <h4 className="m-0">{this.state.salonName}</h4>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userReducer, salonReducer } = state;

  return {
    actionNotify: salonReducer.actionNotify,
    userInfo: userReducer.userInfo,
  };
}

export default connect(mapStateToProps, {
  getUserInfo,
  guessSalonGet,
  cleanUserInfo
})(HeaderUser);
