import React, { Component } from 'react';
import './../../../styles/components/linkline.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

class SalonSuspended extends Component {
  render() {
    return (
      <div className="container-fluid" style={{ maxWidth: 1200 }} id="user-completion">
        <h1 className=" d-flex  justify-content-center" style={{ fontSize: '22px', color: 'black' }}>
            店舗の営業が停止しております
        </h1>
        <div className="w-100 card-completion text-dark mt-70">
          <div className="card-completion-main w-100 d-flex flex-column text-center justify-content-center pt-40 pb-70" style={{ border: '2px solid #333' }}>
            <div>
              <i style={{ fontSize: '55px' }} class="fa-regular fa-circle-xmark"></i>
            </div>
            <div className="card-completion-content d-flex flex-column justify-content-center">
              <h4 className="my-10" style={{ fontSize: '20px' }}></h4>
              <p className="m-0 PC-version">
                現在、当サロンは営業を停止しております。
                <br />
                そのため、過去の予約URLをご利用いただいても新たな予約を行うことはできません。
                <br />
                <br />
                営業再開の際には、改めてお知らせいたします。
                <br />
                ご不便をおかけし申し訳ございませんが、ご理解いただけますようお願い申し上げます。
              </p>
              <p className="m-0 mobile-version">
                現在、当サロンは営業を停止しております。
                <br />
                そのため、過去の予約URLをご利用いただいても新たな予約を行うことはできません。
                <br />
                <br />
                営業再開の際には、改めてお知らせいたします。
                <br />
                ご不便をおかけし申し訳ございませんが、ご理解いただけますようお願い申し上げます。
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(SalonSuspended));
