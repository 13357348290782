import React, { Component } from 'react';
import { connect } from 'react-redux';
import { staffReducer } from '../../../reducers/staffReducer';
import { getGuessServiceList, setGuessChoice, getGuessChoice, notifyAction, cleanGuessChoice } from '../../../actions';
import { TYPES } from '../../../actions';
import _ from 'lodash';
import Func from '../../../utils/Func';
import { withRouter } from 'react-router-dom';

class OptionSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeOption: null,
            salon_uuid: (new URLSearchParams(window.location.search)).get('sid') ?? "",
            line_id: (new URLSearchParams(window.location.search)).get('line_user_id') ?? "",
            options: []
        };
    }

    componentDidMount() {
        this.props.getGuessServiceList({
            salon_uuid: this.state.salon_uuid
        });
    }

    UNSAFE_componentWillReceiveProps(props) {
        // console.log("OptionSelect UNSAFE_componentWillReceiveProps props: ", props);
        const { type } = props.actionNotify;
        const { errorCode, message } = Func.getError({ ...props })
        if (!type) return
        switch (type) {
            case TYPES.ACTION_GET_GUESS_LIST_SERVICES_SUCCESSFULLY:
                const options = _.get(props.actionNotify, 'service', []);
                const sortedOptions = this.sortOptionsByPriority([...options]);
                this.setState({
                    options: sortedOptions
                }, () => {
                    this.props.getGuessChoice();
                });
                break;
            case TYPES.ACTION_GET_GUESS_LIST_SERVICES_FAIL:
                break;
            case TYPES.ACTION_GET_GUEST_CHOICE:
                const option = _.get(props, 'actionNotify.guessChoicing.option', {});
                // this.props.setGuessChoice({option: option});
                const optionId = _.get(option, 'id', -1);
                const index = this.state.options.findIndex(item => item.id === optionId);
                this.setActiveOption(index);
                break;
            case TYPES.ACTION_SET_GUESS_CHOICE:
                if (this.props.history) {
                    this.props.history.push(`/user/schedule?sid=${this.state.salon_uuid}${this.state.line_id ? `&line_user_id=${this.state.line_id}` : ''}`);
                } else {
                    window.location.href = `/user/schedule?sid=${this.state.salon_uuid}${this.state.line_id ? `&line_user_id=${this.state.line_id}` : ''}`;
                }

                break;
            case TYPES.ACTION_USER_GET_USER_INFO_SUCCESS:
                const salon_uuid = _.get(props, 'actionNotify.user.salon_uuid', '');
                break;
            case TYPES.ACTION_CALL_API_FAIL:

                break;
            default:
                break;
        }
        this.props.notifyAction({ type, error_code: errorCode, message });
    }
    sortOptionsByPriority = (options = [...this.state.options]) => {
        const filteredOptions = options.filter(option => option.priority !== 0);
        const sortedOptions = filteredOptions.sort((a, b) => {
            if (a.priority !== b.priority) {
                return a.priority - b.priority;
            } else {
                return b.id - a.id;
            }
        });
        return sortedOptions;
    };



    setActiveOption = (index, cb = () => { }) => {
        if (index < 0) return
        this.setState({ activeOption: index }, () => {
            cb();
        });
    };
    formatPrice = (price) => {
        if (typeof price === 'number') {
          price = price.toString();
        }
        if (typeof price !== 'string' || price.trim() === '') {
          return '0';
        }
        let [integerPart = '', decimalPart = ''] = price.split('.');
        let formattedIntegerPart = parseInt(integerPart).toLocaleString();
        return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : `${formattedIntegerPart}`;
      }

    render() {
        const { activeOption, options } = this.state;
        const sortedOptions = this.sortOptionsByPriority();
        // console.log("option",sortedOptions)

        return (
            <div className='d-flex flex-column'>

                <div className="w-100 option-container text-dark d-flex flex-column justify-content-start ">
                    {sortedOptions.map((item, index) => (
                        <div to={item.path} key={item.id} className="tab-div text-dark w-100">
                            <div
                                style={{ cursor: "pointer", userSelect: "none", border: "1px solid #333" }}
                                className={`option-select ${activeOption === index ? 'active' : ''} w-100 d-flex align-items-center py-10 px-10 mb-30`}
                                onClick={() => this.setActiveOption(index, () => this.props.setGuessChoice({ option: item }))}
                            >
                                {item.image_url && <img src={item.image_url} alt="" 
                                    style={{ height: '150px', 
                                            width: '150px',
                                            objectFit: 'contain',
                                            border: "1px solid lightgray",
                                            flexShrink: 0, 
                                            flexGrow: 0, 
                                            }} />}
                                {!item.image_url && <img src="https://salon-reservation.stg.yosapark-sys.com/static/images/yosapark-course-default-image_01.png" alt="" 
                                    style={{ height: '150px', 
                                            width: '150px',
                                            objectFit: 'contain',
                                            border: "1px solid lightgray",
                                            flexShrink: 0, 
                                            flexGrow: 0, 
                                            }} />
                                }
                                <div className='d-flex flex-column justify-content-between flex-grow-1'
                                    style={{paddingLeft:'0.5rem'}}
                                >

                                    <h5 style={{ margin: "0 20px 0 0", fontSize: '1rem' }}>{item.name}</h5>
                                    <h5 style={{ margin: "0 20px 0 0", fontSize: '1rem' }}>{item.description}</h5>
                                    <h4 style={{ color: "red", margin: "0 20px 0 0", fontSize: '1rem' }}  >{this.formatPrice(item.price)}円</h4>

                                </div>
                                <i className="fa fa-chevron-right"></i>
                            </div>
                        </div>
                    ))}
                </div>


            </div>

        );
    }
}

function mapStateToProps(state) {
    const { staffReducer } = state;
    const { userInfo } = state.userReducer;
    return {
        actionNotify: staffReducer.actionNotify,
        guessChoicing: staffReducer.guessChoicing,
        userInfo: userInfo,
    };
}
  
export default withRouter(connect(mapStateToProps, {
    getGuessServiceList, 
    setGuessChoice, 
    getGuessChoice,
    notifyAction,
    cleanGuessChoice,
})(OptionSelect));
