import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import moment from 'moment';
import { connect } from 'react-redux';
import { TYPES } from '../../../actions';
import { cleanGuessChoice, cancelBooking, guestCheckValidLineUserId, getBookingRefGuest, guessSalonGet, cleanUserInfo } from '../../../actions';
// import { LANGUAGES, getTranslate, notify } from '../../../utils';
import _ from 'lodash';

class Booking extends Component {
  constructor(props) {
    super(props);

    let params = new URLSearchParams(window.location.search);
    this.state = {
      salon_uuid: params.get('sid') ?? '',
      line_id: params.get('line_user_id') ?? '',
      booking_uuid: params.get('booking_uuid') ?? '',
      booking_ref: '',
      actionSalonNotify: {},
    };
  }
  componentDidMount() {
    console.log('this.props: ', this.props);
    
    
    if (this.state.line_id) {
      localStorage.removeItem('booking_ref');
      this.props.getBookingRefGuest();
    }
    if (window.location.pathname == '/booking/cancel' && this.state.booking_uuid) {
      let route = `/user/crconfirm?booking_uuid=${this.state.booking_uuid}`;
      if (this.props.history) {
        this.props.history.push(route);
      } else {
        window.location.href = route;
      }
    }else{
      this.props.guessSalonGet({ code: this.state.salon_uuid });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('Booking UNSAFE_componentWillReceiveProps props: ', props);
    let { type } = props.actionNotify;
    console.log("type: ", type);
    if (JSON.stringify(this.state.actionSalonNotify) !== JSON.stringify(props.actionSalonNotify)) {
      console.log("props.actionSalonNotify: ", props.actionSalonNotify);
      type = props.actionSalonNotify.type;
      this.setState({
        actionSalonNotify: props.actionSalonNotify,
      });
    }
    console.log('type: ', type);
    switch (type) {
      case TYPES.ACTION_GET_BOOKING_REF_GUEST:
        if (this.state.line_id) {
          if (window.location.pathname == '/booking') {
            this.props.guestCheckValidLineUserId({ line_user_id: this.state.line_id });
          }
        }
        break;
      case TYPES.ACTION_GUEST_LINE_USER_ID_INVALID:
        // let msgError = 'Line user id is not valid';
        // notify(msgError, 'error', 8000);
        this.props.history.push('/booking/booking-invalid-line-user-id');
        break;
      case TYPES.ACTION_GUEST_LINE_USER_ID_VALID:
        this.props.cleanGuessChoice(() => {
          if (this.state.line_id && this.state.salon_uuid) {
            let route = `/user/option?sid=${this.state.salon_uuid}&line_user_id=${this.state.line_id}`;
            if (this.props.history) {
              this.props.history.push(route);
            } else {
              window.location.href = route;
            }
          }
        });

        break;
      case TYPES.ACTION_GET_GUESS_SALON_SUCCESS:
        if (_.get(props, 'actionSalonNotify.salon_status', '') === "close"){
          let role = _.get(this.props, 'userInfo.role');
          let isStaff = role === 1;
          if (isStaff) {
            this.props.cleanUserInfo();
            localStorage.removeItem('salon_token');
            window.location.href = '/login';
          }else{
            let route = `/booking/salon-suspended`;
            if (this.props.history) {
              this.props.history.push(route);
            } else {
              window.location.href = route;
            }
          }
          return
        }
      break;
      default:
        break;
    }
  }

  render() {
    return <></>;
  }
}

function mapStateToProps(state) {
  const { staffReducer, salonReducer } = state;

  return {
    // menuDetail: staffReducer.menuDetail,
    actionNotify: staffReducer.actionNotify,
    actionSalonNotify: salonReducer.actionNotify,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    cleanGuessChoice,
    cancelBooking,
    guestCheckValidLineUserId,
    getBookingRefGuest,
    guessSalonGet,
    cleanUserInfo
  })(Booking)
);
