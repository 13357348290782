import { TYPES } from '../actions';
import _ from 'lodash';
import { notify } from '../utils';

const initialState = {
  salon_list: {},
  actionNotify: {},
};

export const salonReducer = (state = initialState, action) => {
  console.log('action: ', action);
  switch (action.type) {
    case TYPES.ACTION_SALON_GET_LIST_SUCCESS:
      return {
        ...state,
        salon_list: _.pick(action.payload.data, ['total_record', 'data']),
      };

    case TYPES.ACTION_SALON_SAVE_SUCCESS:
    case TYPES.ACTION_GET_GUESS_SALON_SUCCESS:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          salon_name: _.get(action, 'payload.data.data.name'),
          salon_gender: _.get(action, 'payload.data.data.salon_gender'),
          salon_status: _.get(action, 'payload.data.data.status') === 1 ? 'close' : 'open',
        }
      };
    case TYPES.ACTION_CALL_API_FAIL:
      return {
        ...state,
        actionNotify: {
          time: +new Date(),
          type: action.type,
          error_code: _.get(action.payload, 'message.error_code') || _.get(action.payload, 'data.error_code'),
        },
      };
    default:
      return state;
  }
};
